//import store from "../index";

const AUTHTOKEN_LOGOUT = "AUTHTOKEN_LOGOUT";
const EQUIPMENT_REQUEST = "EQUIPMENT_REQUEST";
const EQUIPMENT_ERROR = "EQUIPMENT_ERROR";
const EQUIPMENT_SUCCESS = "EQUIPMENT_SUCCESS";
const EQUIPMENTDETAIL_REQUEST = "EQUIPMENTDETAIL_REQUEST";
const EQUIPMENTALARMS_REQUEST = "EQUIPMENTALARMS_REQUEST";
const EQUIPMENTRESYNC_REQUEST = "EQUIPMENTRESYNC_REQUEST";
const SPARES_INVENTORY_REQUEST = "SPARES_INVENTORY_REQUEST";
const SPARES_INVENTORY_SUCCESS = "SPARES_INVENTORY_SUCCESS";
const SPARES_INVENTORY_CREATE_ENTRY = "SPARES_INVENTORY_CREATE_ENTRY";
const SPARES_INVENTORY_UPDATE_SUCCESS = "SPARES_INVENTORY_UPDATE_SUCCESS";
const SPARES_INVENTORY_UPDATE = "SPARES_INVENTORY_UPDATE";
const SPARES_INVENTORY_DELETE_ENTRY = "SPARES_INVENTORY_DELETE_ENTRY";
const SPARES_INVENTORY_UPDATE_ENTRY = "SPARES_INVENTORY_UPDATE_ENTRY";

const PORT_LIGHT_REQUEST = "PORT_LIGHT_REQUEST";
const PORT_TRAFFIC_REQUEST = "PORT_TRAFFIC_REQUEST";
const PORT_LIGHTHISTORY_REQUEST = "PORT_LIGHTHISTORY_REQUEST";
const PORT_TRAFFICHISTORY_REQUEST = "PORT_TRAFFICHISTORY_REQUEST";
const PORT_LINKSTATUS_REQUEST = "PORT_LINKSTATUS_REQUEST";
const PORT_DETAILS_REQUEST = "PORT_DETAILS_REQUEST";
const SITE_EQUIPMENT_REQUEST = "SITE_EQUIPMENT_REQUEST";
const SITE_EQUIPMENT_SUCCESS = "SITE_EQUIPMENT_SUCCESS";
const SITE_EQUIPMENT_ERROR = "SITE_EQUIPMENT_ERROR";
const EQUIPMENT_CREATE = "EQUIPMENT_CREATE";
const EQUIPMENT_CREATE_SUCCESS = "EQUIPMENT_CREATE_SUCCESS";
const EQUIPMENT_CREATE_ERROR = "EQUIPMENT_CREATE_ERROR";
const EQUIPMENT_UPDATE = "EQUIPMENT_UPDATE";
const EQUIPMENT_UPDATE_SUCCESS = "EQUIPMENT_UPDATE_SUCCESS";
const EQUIPMENT_UPDATE_ERROR = "EQUIPMENT_UPDATE_ERROR";
const EQUIPMENT_DELETE = "EQUIPMENT_DELETE";
const EQUIPMENT_DELETE_SUCCESS = "EQUIPMENT_DELETE_SUCCESS";
const EQUIPMENT_DELETE_ERROR = "EQUIPMENT_DELETE_ERROR";

//AWX Jobs
const EQUIPMENT_LOG_REQUEST = "EQUIPMENT_LOG_REQUEST";
const EQUIPMENTRSI_REQUEST = "EQUIPMENTRSI_REQUEST";
const EQUIPMENT_VAR_LOG_REQUEST = "EQUIPMENT_VAR_LOG_REQUEST";
const EQUIPMENT_CONFIG_RETRIEVE = "EQUIPMENT_CONFIG_RETRIEVE";
const EQUIPMENT_BOUNCE_INTERFACE = "EQUIPMENT_BOUNCE_INTERFACE";
const EQUIPMENT_CLEAR_MACTABLE = "EQUIPMENT_CLEAR_MACTABLE";
const EQUIPMENT_DEVICE_CHECK = "EQUIPMENT_DEVICE_CHECK";
const EQUIPMENT_NETBOX_CHECK = "EQUIPMENT_NETBOX_CHECK";
const EQUIPMENT_NETBOX_UPDATE = "EQUIPMENT_NETBOX_UPDATE";
const EQUIPMENT_ROLLBACK_COMPARE = "EQUIPMENT_ROLLBACK_COMPARE";
const EQUIPMENT_ALARM_CHECK = "EQUIPMENT_ALARM_CHECK";
const EQUIPMENT_FACTS_CHECK = "EQUIPMENT_FACTS_CHECK";
const EQUIPMENT_HARDWARE_RETRIEVE = "EQUIPMENT_HARDWARE_RETRIEVE";
const EQUIPMENT_INTERFACE_RETRIEVE = "EQUIPMENT_INTERFACE_RETRIEVE";
const EQUIPMENT_INTERFACES_RETRIEVE = "EQUIPMENT_INTERFACES_RETRIEVE"
const EQUIPMENT_INTERFACE_OPTICS = "EQUIPMENT_INTERFACE_OPTICS";
const EQUIPMENT_LICENSES_RETRIEVE = "EQUIPMENT_LICENSES_RETRIEVE";
const EQUIPMENT_INTERFACE_LOGS = "EQUIPMENT_INTERFACE_LOGS";
const EQUIPMENT_INSTANCE_MACTABLE = "EQUIPMENT_INSTANCE_MACTABLE";
const EQUIPMENT_UPTIME_RETRIEVE = "EQUIPMENT_UPTIME_RETRIEVE";
const VPLS_CONNECTIONS_RETRIEVE = "VPLS_CONNECTIONS_RETRIEVE";
const ROUTER_IPSEC_RESET = "ROUTER_IPSEC_RESET";
const CIENA_INVENTORY_REQUEST = "CIENA_INVENTORY_REQUEST";
const JUNIPER_INVENTORY_REQUEST = "JUNIPER_INVENTORY_REQUEST";
const EQUIPMENT_INVENTORY_REPORT_REQUEST = "EQUIPMENT_INVENTORY_REPORT_REQUEST";

//Equipment Profile Generation
const SITE_MANAGER_PROFILE_REQUEST = "SITE_MANAGER_PROFILE_REQUEST";
const MREMOTE_PROFILE_REQUEST = "MREMOTE_PROFILE_REQUEST";

//SPack Endpoints
const SPACK_STATS_REQUEST = "SPACK_STATS_REQUEST";
const SPACK_STATS_SUCCESS = "SPACK_STATS_SUCCESS";
const SPACK_STATS_ERROR = "SPACK_STATS_ERROR";

import Vue from "vue";
import axios from "axios";

const state = { status: "", equipment: [], inventory: [] };

const getters = {
  getEquipment: (state) => state.equipment,
  searchEquipment: (state) => (searchParam) => {
    var searchTerm = new RegExp(searchParam, "i");
    return state.equipment.filter(
      (equipment) =>
        searchTerm.test(equipment.equipmentname) ||
        searchTerm.test(equipment.ipaddress)
    );
  },
  getSiteEquipment:(state) => (siteid) => {
    return state.equipment.filter(thisEquipment => thisEquipment.siteid == siteid)
  },
  getInventory: (state) => state.inventory,
  getShelf: (state) => (shelfId) => {
    const shelf = state.equipment.find(
      (shelf) => shelf.equipmentid === shelfId
    );
    return shelf;
  },
  getFeeding: (state) => (shelfId) => {
    return state.equipment.filter((eq) => eq.spack === shelfId);
  },
  getSparePartsInventory: (state) => state.inventory,
};

const actions = {
  [EQUIPMENT_REQUEST]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(EQUIPMENT_REQUEST);
      commit("addLoading", {
        handle: "equipment",
        name: "Load Equipment Data",
      });
      axios({ url: Vue.prototype.$apiServer + "shelves", method: "GET" })
        .then((resp) => {
          commit("removeLoading", "equipment");
          if (resp.data.result === "Success") {
            commit(EQUIPMENT_SUCCESS, resp.data);
            resolve(resp.data);
          } else {
            commit(EQUIPMENT_ERROR);
            reject(resp.data);
          }
          resolve(resp);
        })
        .catch((err) => {
          commit(EQUIPMENT_ERROR);
          commit("removeLoading", "equipment");
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [SPARES_INVENTORY_UPDATE]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(SPARES_INVENTORY_REQUEST);
      commit("addLoading", {
        handle: "inventoryupdate",
        name: "update Spare Parts Inventory",
      });
      axios({ url: Vue.prototype.$apiServer + "inventory/ventia", method: "POST" })
        .then((resp) => {
          commit("removeLoading", "inventoryupdate");
          if (resp.data.result === "Success") {
            commit(SPARES_INVENTORY_UPDATE_SUCCESS);
            resolve(resp.data);
          } else {
            commit(EQUIPMENT_ERROR);
            reject(resp.data);
          }
          resolve(resp);
        })
        .catch((err) => {
          commit(EQUIPMENT_ERROR);
          commit("removeLoading", "inventoryupdate");
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [SPARES_INVENTORY_UPDATE]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(SPARES_INVENTORY_REQUEST);
      commit("addLoading", {
        handle: "inventoryupdate",
        name: "update Spare Parts Inventory",
      });
      axios({ url: Vue.prototype.$apiServer + "inventory/ventia", method: "POST" })
        .then((resp) => {
          commit("removeLoading", "inventoryupdate");
          if (resp.data.result === "Success") {
            commit(SPARES_INVENTORY_UPDATE_SUCCESS);
            resolve(resp.data);
          } else {
            commit(EQUIPMENT_ERROR);
            reject(resp.data);
          }
          resolve(resp);
        })
        .catch((err) => {
          commit(EQUIPMENT_ERROR);
          commit("removeLoading", "inventoryupdate");
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [SPARES_INVENTORY_CREATE_ENTRY]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit(SPARES_INVENTORY_REQUEST);
      commit("addLoading", {
        handle: "addInventoryEntry",
        name: "Adding Inventory Item",
      });
      axios({
        url: Vue.prototype.$apiServer + 'inventory',
        method: "POST",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "addInventoryEntry"
          );
          if (resp.data.result === "Success") {
            commit(SPARES_INVENTORY_UPDATE_SUCCESS);
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          resolve(resp);
        })
        .catch((err) => {
          commit(EQUIPMENT_ERROR);
          commit(
            "removeLoading",
            "addInventoryEntry"
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [SPARES_INVENTORY_UPDATE_ENTRY]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit(SPARES_INVENTORY_REQUEST);
      commit("addLoading", {
        handle: "updateInventoryEntry",
        name: "Update Inventory Item",
      });
      axios({
        url: Vue.prototype.$apiServer + 'inventory/' + payload.uuid,
        method: "PUT",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "updateInventoryEntry"
          );
          if (resp.data.result === "Success") {
            commit(SPARES_INVENTORY_UPDATE_SUCCESS);
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          resolve(resp);
        })
        .catch((err) => {
          commit(EQUIPMENT_ERROR);
          commit(
            "removeLoading",
            "updateInventoryEntry"
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [SPARES_INVENTORY_DELETE_ENTRY]: ({commit, dispatch}, deletedItem) => {
    return new Promise((resolve, reject) => {
        commit(SPARES_INVENTORY_DELETE_ENTRY);
        commit('addLoading', {handle:'inventorydelete'+deletedItem , name:'Delete Equipment'})
        axios({
            url: Vue.prototype.$apiServer + 'inventory/' + deletedItem,
            method: "DELETE"
        })
            .then(resp => {
                commit('removeLoading', 'inventorydelete'+deletedItem)
                if (resp.data.result === 'Success') {
                    commit(EQUIPMENT_DELETE_SUCCESS, resp.data);
                    resolve(resp);
                } else {
                    commit(EQUIPMENT_DELETE_ERROR)
                    reject(resp.data)
                }
            })
            .catch((err) => {
                commit(EQUIPMENT_DELETE_ERROR);
                commit('removeLoading', 'inventorydelete'+deletedItem)
                // if resp is unauthorized, logout, to
                if (err.response.status === 403) {
                    dispatch(AUTHTOKEN_LOGOUT);
                }
                reject(err.response.data)
            });
    })
},
  [SITE_EQUIPMENT_REQUEST]: ({ commit, dispatch }, siteid) => {
    return new Promise((resolve, reject) => {
      commit(SITE_EQUIPMENT_REQUEST);
      commit("addLoading", {
        handle: "siteequipment",
        name: "Load Site Equipment Data",
      });
      axios({ url: Vue.prototype.$apiServer + "site/"+ siteid + "/equipment", method: "GET" })
        .then((resp) => {
          commit("removeLoading", "siteequipment");
          if (resp.data.result === "Success") {
            commit(SITE_EQUIPMENT_SUCCESS, resp.data);
            resolve(resp.data);
          } else {
            commit(SITE_EQUIPMENT_ERROR);
            reject(resp.data);
          }
          resolve(resp);
        })
        .catch((err) => {
          commit(SITE_EQUIPMENT_ERROR);
          commit("removeLoading", "siteequipment");
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENTDETAIL_REQUEST]: ({ commit, dispatch }, equipmentid) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "equipmentdetail",
        name: "Load Equipment Data",
      });
      axios({
        url: Vue.prototype.$apiServer + "equipment/" + equipmentid,
        method: "GET",
      })
        .then((resp) => {
          commit("removeLoading", "equipmentdetail");
          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          resolve(resp);
        })
        .catch((err) => {
          commit("removeLoading", "equipmentdetail");
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENTALARMS_REQUEST]: ({ commit, dispatch }, equipmentid) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "equipmentalarms",
        name: "Load Equipment Alarms",
      });
      axios({
        url: Vue.prototype.$apiServer + "equipment/" + equipmentid + "/alarms",
        method: "GET",
      })
        .then((resp) => {
          commit("removeLoading", "equipmentalarms");
          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          resolve(resp);
        })
        .catch((err) => {
          commit("removeLoading", "equipmentalarms");
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENTRESYNC_REQUEST]: ({ commit, dispatch }, equipmentid) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "equipmentresync",
        name: "Resync Equipment Data",
      });
      axios({
        url: Vue.prototype.$apiServer + "equipment/" + equipmentid + "/update",
        method: "POST",
      })
        .then((resp) => {
          commit("removeLoading", "equipmentresync");
          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          resolve(resp);
        })
        .catch((err) => {
          commit("removeLoading", "equipmentresync");
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },

  //AWX Jobs
  [ROUTER_IPSEC_RESET]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "resetIPSEC",
        name: "Resetting Microtik IPSec SA's",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/run/resetipsec",
        method: "POST",
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "resetIPSEC"
          );
          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          resolve(resp);
        })
        .catch((err) => {
          commit(
            "removeLoading",
            "resetIPSEC"
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENT_CONFIG_RETRIEVE]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "equipmentconfig" + payload.equipmentarray.tostring,
        name: "Request RSI",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/get/config",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "equipmentconfig" + payload.equipmentarray.tostring
          );
          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          resolve(resp);
        })
        .catch((err) => {
          commit(
            "removeLoading",
            "equipmentconfig" + payload.equipmentarray.tostring
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENTRSI_REQUEST]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "equipmentrsi" + payload.equipmentarray.tostring,
        name: "Request RSI",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/get/rsi",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "equipmentrsi" + payload.equipmentarray.tostring
          );
          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          resolve(resp);
        })
        .catch((err) => {
          commit(
            "removeLoading",
            "equipmentrsi" + payload.equipmentarray.tostring
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },


  [CIENA_INVENTORY_REQUEST]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "log" + payload.equipmentarray.tostring,
        name: "Ciena Inventory",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/run/cienainventory",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "log" + payload.equipmentarray.tostring
          );

          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }

          resolve(resp);
        })
        .catch((err) => {
        //  
          commit(
            "removeLoading",
            "log" + payload.equipmentarray.tostring
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },

  [JUNIPER_INVENTORY_REQUEST]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "log" + payload.equipmentarray.tostring,
        name: "Juniper Inventory",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/run/juniperinventory",
        method: "GET",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "log" + payload.equipmentarray.tostring
          );

          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }

          resolve(resp);
        })
        .catch((err) => {
        //  
          commit(
            "removeLoading",
            "log" + payload.equipmentarray.tostring
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },

  [EQUIPMENT_INVENTORY_REPORT_REQUEST]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "shelfInventory",
        name: "Generating Shelf Inventory",
      });
      axios({
        url: Vue.prototype.$apiServer + "equipment/"+payload.equipmentid+"/inventory/" + payload.format,
        method: "GET",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "shelfInventory"
          );
            resolve(resp.data);
        })
        .catch((err) => {  
          commit(
            "removeLoading",
            "shelfInventory"
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },

  









  [EQUIPMENT_LOG_REQUEST]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "log" + payload.equipmentarray.tostring,
        name: "Request log",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/get/log",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "log" + payload.equipmentarray.tostring
          );

          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }

          resolve(resp);
        })
        .catch((err) => {
        //  
          commit(
            "removeLoading",
            "log" + payload.equipmentarray.tostring
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENT_VAR_LOG_REQUEST]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "varlog" + payload.equipmentarray.tostring,
        name: "Request var/log",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/get/varlog",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "varlog" + payload.equipmentarray.tostring
          );

          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }

          resolve(resp);
        })
        .catch((err) => {
        //  
          commit(
            "removeLoading",
            "varlog" + payload.equipmentarray.tostring
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENT_UPTIME_RETRIEVE]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "uptime" + payload.equipmentarray.tostring,
        name: "Request var/log",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/show/uptime",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
         
          commit(
            "removeLoading",
            "uptime" + payload.equipmentarray.tostring
          );

          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }

          resolve(resp);
        })
        .catch((err) => {
        //  
          commit(
            "removeLoading",
            "uptime" + payload.equipmentarray.tostring
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENT_BOUNCE_INTERFACE]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "bounceinterface" + payload.portid,
        name: "Request var/log",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/run/bounceport",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
        // 
          commit(
            "removeLoading","bounceinterface" + payload.portid,
          );

          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          
          resolve(resp);
        })
        .catch((err) => {
        //  
          commit(
            "removeLoading",
            "bounceinterface" + payload.portid,
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENT_INTERFACE_LOGS]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "interfacelog",
        name: "Requesting Interface Log",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/show/logsfiltered",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "interfacelog"
          );

          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          
          resolve(resp);
        })
        .catch((err) => {
        //  
          commit(
            "removeLoading",
            "interfacelog"
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENT_INSTANCE_MACTABLE]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "instancemactable",
        name: "Requesting Instance MAC Table",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/show/mactable",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "instancemactable"
          );

          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          
          resolve(resp);
        })
        .catch((err) => {
       //   
          commit(
            "removeLoading",
            "instancemactable"
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENT_CLEAR_MACTABLE]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "clearmactable",
        name: "Clearing Interface MAC Table...",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/run/clearmactable",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "clearmactable"
          );

          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          
          resolve(resp);
        })
        .catch((err) => {
          
          commit(
            "removeLoading",
            "clearmactable" + payload.equipmentid + payload.interface
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENT_DEVICE_CHECK]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "devicecheck",
        name: "Checking Shelf Config",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/run/config/devicecheck",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "devicecheck"
          );

          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          
          resolve(resp);
        })
        .catch((err) => {
          
          commit(
            "removeLoading",
            "devicecheck"
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENT_NETBOX_CHECK]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "netboxcheck",
        name: "Checking Netbox Config",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/run/config/netboxcheck",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "netboxcheck"
          );

          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          
          resolve(resp);
        })
        .catch((err) => {
          
          commit(
            "removeLoading",
            "netboxcheck"
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENT_NETBOX_UPDATE]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "netboxupdate",
        name: "Checking Netbox Config",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/run/update/netbox",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "netboxupdate"
          );

          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          
          resolve(resp);
        })
        .catch((err) => {
          
          commit(
            "removeLoading",
            "netboxupdate"
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENT_ROLLBACK_COMPARE]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "rollbackcompare",
        name: "Comparing Rollback",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/run/rollbackcompare",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "rollbackcompare"
          );

          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          
          resolve(resp);
        })
        .catch((err) => {
          
          commit(
            "removeLoading",
            "rollbackcompare"
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENT_ALARM_CHECK]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "getalarms",
        name: "Retrieving Shelf Alarms",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/show/alarms",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "getalarms"
          );

          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          
          resolve(resp);
        })
        .catch((err) => {
          
          commit(
            "removeLoading",
            "getalarms"
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENT_FACTS_CHECK]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "getfacts",
        name: "Retrieving Shelf Facts",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/show/facts",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "getfacts"
          );

          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          
          resolve(resp);
        })
        .catch((err) => {
          
          commit(
            "removeLoading",
            "getfacts"
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENT_HARDWARE_RETRIEVE]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "gethardware",
        name: "Retrieving Shelf Hardware",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/show/hardware",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "gethardware"
          );

          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          
          resolve(resp);
        })
        .catch((err) => {
          
          commit(
            "removeLoading",
            "gethardware"
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENT_INTERFACE_RETRIEVE]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "getinterface",
        name: "Retrieving Interface",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/show/interface",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "getinterface"
          );

          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          
          resolve(resp);
        })
        .catch((err) => {
          
          commit(
            "removeLoading",
            "getinterface"
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENT_INTERFACES_RETRIEVE]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "getinterfaces",
        name: "Retrieving Interfaces",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/show/interfaces",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "getinterfaces"
          );

          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          
          resolve(resp);
        })
        .catch((err) => {
          
          commit(
            "removeLoading",
            "getinterfaces"
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENT_INTERFACE_OPTICS]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "getinterfaceOptics",
        name: "Retrieving Interface Optics",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/show/interfaceoptics",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "getinterfaceOptics"
          );

          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          
          resolve(resp);
        })
        .catch((err) => {
          
          commit(
            "removeLoading",
            "getinterfaceOptics"
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENT_LICENSES_RETRIEVE]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "getalarms",
        name: "Retrieving Shelf Licenses",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/show/license",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "getalarms"
          );

          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          
          resolve(resp);
        })
        .catch((err) => {
          
          commit(
            "removeLoading",
            "getalarms"
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [SPACK_STATS_REQUEST]: ({ commit }, equipmentid) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "spackdetail",
        name: "Load SPack Data",
      });
      axios({
        url: Vue.prototype.$apiServer + "tools/spackstats/" + equipmentid,
        method: "GET",
      })
        .then((resp) => {
          commit("removeLoading", "spackdetail");
          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          resolve(resp);
        })
        .catch((err) => {
          commit("removeLoading", "spackdetail");
          reject(err.response.data);
        });
    });
  },
  [PORT_LIGHT_REQUEST]: ({ commit, dispatch }, portID ) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "portlight" + portID,
        name: "Load Port " + portID + " Light Levels",
      });
      axios({
        url: Vue.prototype.$apiServer + "port/" + portID + "/lightlevels",
        method: "GET",
      })
        .then((resp) => {
          commit("removeLoading", "portlight" + portID);
          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
        })
        .catch((err) => {
          commit("removeLoading", "portlight" + portID);
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [PORT_TRAFFIC_REQUEST]: ({ commit, dispatch },  portID ) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "porttraffic" + portID,
        name: "Load Port " + portID + " Traffic",
      });
      axios({
        url: Vue.prototype.$apiServer + "port/" + portID + "/trafficvalues",
        method: "GET",
      })
        .then((resp) => {
          commit("removeLoading", "porttraffic" + portID);
          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
        })
        .catch((err) => {
          commit("removeLoading", "porttraffic" + portID);
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [PORT_LIGHTHISTORY_REQUEST]: ({commit, dispatch}, { portID, charttimespan}) => {
    return new Promise((resolve, reject) => {
        commit('addLoading', {handle: 'portlighthistory'+portID, name: 'Load Port '+portID+ ' Light History'})
        axios({url: Vue.prototype.$apiServer + 'port/'+portID + '/lighthistory/' + charttimespan, method: "GET"})
            .then(resp => {
                commit('removeLoading', 'portlighthistory'+portID)
                if (resp.data.result === 'Success') {
                    resolve(resp.data)
                } else {
                    reject(resp.data)
                }
            })
            .catch((err) => {
                commit('removeLoading', 'portlighthistory'+portID)
                // if resp is unauthorized, logout, to
                if (err.response.status === 403) {
                    dispatch(AUTHTOKEN_LOGOUT);
                }
                reject(err.response.data)
            });
    })
  },
  [PORT_TRAFFICHISTORY_REQUEST]: ({commit, dispatch}, { portID, charttimespan}) => {
    return new Promise((resolve, reject) => {
        commit('addLoading', {handle: 'porttraffichistory'+portID, name: 'Load Port '+portID+ ' Traffic History'})
        axios({url: Vue.prototype.$apiServer + 'port/'+portID + '/traffichistory/' + charttimespan, method: "GET"})
            .then(resp => {
                commit('removeLoading', 'porttraffichistory'+portID)
                if (resp.data.result === 'Success') {
                    resolve(resp.data)
                } else {
                    reject(resp.data)
                }
            })
            .catch((err) => {
                commit('removeLoading', 'porttraffichistory'+portID)
                // if resp is unauthorized, logout, to
                if (err.response.status === 403) {
                    dispatch(AUTHTOKEN_LOGOUT);
                }
                reject(err.response.data)
            });
    })
  },
  [PORT_LINKSTATUS_REQUEST]: ({commit, dispatch},  portID ) => {
      return new Promise((resolve, reject) => {
          commit('addLoading', {handle: 'portlink'+portID, name: 'Load Port '+portID+ ' Status'})
          axios({url: Vue.prototype.$apiServer + 'port/'+portID + '/operationalstatus', method: "GET"})
              .then(resp => {
                  commit('removeLoading', 'portlink'+portID)
                  if (resp.data.result === 'Success') {
                      resolve(resp.data)
                  } else {
                      reject(resp.data)
                  }
              })
              .catch((err) => {
                  commit('removeLoading', 'portlink'+portID)
                  // if resp is unauthorized, logout, to
                  if (err.response.status === 403) {
                      dispatch(AUTHTOKEN_LOGOUT);
                  }
                  reject(err.response.data)
              });
      })
  },
  [PORT_DETAILS_REQUEST]: ({commit, dispatch}, payload ) => {
    return new Promise((resolve, reject) => {
        commit('addLoading', {handle: 'portdetails'+payload.portid, name: 'Load Port '+payload.portName+ ' Details'})
        axios({url: Vue.prototype.$apiServer + 'port/'+payload.portid, method: "GET"})
            .then(resp => {
                commit('removeLoading', 'portdetails'+payload.portid)
                if (resp.data.result === 'Success') {
                    resolve(resp.data)
                } else {
                    reject(resp.data)
                }
            })
            .catch((err) => {
                commit('removeLoading', 'portdetails'+payload.portid)
                // if resp is unauthorized, logout, to
                if (err.response.status === 403) {
                    dispatch(AUTHTOKEN_LOGOUT);
                }
                reject(err.response.data)
              });
      })
  },
  [VPLS_CONNECTIONS_RETRIEVE]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "getvplsconnections",
        name: "Retrieving VPLS Instance Connections",
      });
      axios({
        url: Vue.prototype.$apiServer + "awx/show/vplsconnections",
        method: "POST",
        data: payload,
      })
        .then((resp) => {
          commit(
            "removeLoading",
            "getvplsconnections"
          );

          if (resp.data.result === "Success") {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
          
          resolve(resp);
        })
        .catch((err) => {
          
          commit(
            "removeLoading",
            "getvplsconnections"
          );
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [EQUIPMENT_CREATE]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(EQUIPMENT_CREATE);
            commit('addLoading', {handle:'equipcreate',name:'Creating New Equipment'})
            axios({
                url: Vue.prototype.$apiServer + 'equipment',
                method: "POST",
                data: payload
            })
                .then(resp => {
                    commit('removeLoading', 'equipcreate')
                    if(resp.data.result === 'Success') {
                        commit(EQUIPMENT_CREATE_SUCCESS, resp.data);
                        dispatch(EQUIPMENT_REQUEST);
                        resolve(resp)
                    } else {
                        commit(EQUIPMENT_CREATE_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit('removeLoading', 'equipcreate')
                    commit(EQUIPMENT_CREATE_ERROR);
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [EQUIPMENT_UPDATE]: ({ commit, dispatch }, payload) => {
      return new Promise((resolve, reject) => {
          commit(EQUIPMENT_UPDATE);
          commit('addLoading', {handle:'equipmentupdate',name:'Updating Equipment'})
          axios({
              url: Vue.prototype.$apiServer + 'equipment/' + payload.equipmentid,
              method: "PUT",
              data: payload
          })
              .then(resp => {
                  commit('removeLoading', 'equipmentupdate')
                  if(resp.data.result === 'Success') {
                      commit(EQUIPMENT_UPDATE_SUCCESS, resp.data);
                      dispatch(EQUIPMENT_REQUEST);
                      resolve(resp)
                  } else {
                      commit(EQUIPMENT_UPDATE_ERROR);
                      reject(resp.data)
                  }
              })
              .catch((err) => {
                  commit('removeLoading', 'equipmentupdate')
                  commit(EQUIPMENT_UPDATE_ERROR);
                  if (err.response.status === 403) {
                      dispatch(AUTHTOKEN_LOGOUT);
                  }
                  reject(err.response.data)
              });
      })
    },
    [EQUIPMENT_DELETE]: ({commit, dispatch}, payload) => {
      return new Promise((resolve, reject) => {
          commit(EQUIPMENT_DELETE);
          commit('addLoading', {handle:'equipmentdelete'+payload ,name:'Delete Equipment'})
          axios({
              url: Vue.prototype.$apiServer + 'equipment/' + payload,
              method: "DELETE"
          })
              .then(resp => {
                  commit('removeLoading', 'equipmentdelete'+payload)
                  if (resp.data.result === 'Success') {
                      commit(EQUIPMENT_DELETE_SUCCESS, resp.data);
                      resolve(resp);
                  } else {
                      commit(EQUIPMENT_DELETE_ERROR)
                      reject(resp.data)
                  }
              })
              .catch((err) => {
                  commit(EQUIPMENT_DELETE_ERROR);
                  commit('removeLoading', 'equipmentdelete'+payload)
                  // if resp is unauthorized, logout, to
                  if (err.response.status === 403) {
                      dispatch(AUTHTOKEN_LOGOUT);
                  }
                  reject(err.response.data)
              });
      })
  },
  [SITE_MANAGER_PROFILE_REQUEST]: ({ commit, dispatch } ) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "getSiteManager",
        name: "Generating Site Manager Profile",
      });
      axios({ 
        url: Vue.prototype.$apiServer + "tools/generate/sitemanagerprofile",
        method: "GET", 
        responseType: 'blob'})
          .then(resp => {
            commit("removeLoading", "getSiteManager");
            resolve(resp.data);    
          } )
          .catch((err) => {
            commit("removeLoading", "getSiteManager");
            if (err.response.status === 403) {
              dispatch(AUTHTOKEN_LOGOUT);
            }
            reject(err.response.data);
          });
      })
  },
  [MREMOTE_PROFILE_REQUEST]: ({ commit, dispatch } ) => {
    return new Promise((resolve, reject) => {
      commit("addLoading", {
        handle: "getMRemote",
        name: "Generating mRemote Profile",
      });
      axios({
        url: Vue.prototype.$apiServer + "tools/generate/mremotexml",
        method: "GET",
        responseType: 'blob',
      })
        .then((resp) => {
          commit("removeLoading", "getMRemote");
          resolve(resp.data);       
        })
        .catch((err) => {
          commit("removeLoading", "getMRemote");
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
};

const mutations = {
  [EQUIPMENT_REQUEST]: (state) => {
    state.status = "loading";
  },
  [EQUIPMENT_SUCCESS]: (state, resp) => {
    state.status = "success";
    Vue.set(state, "equipment", resp.payload);
  },
  [EQUIPMENT_ERROR]: (state) => {
    state.status = "error";
  },
  [SPARES_INVENTORY_REQUEST]: (state) => {
    state.status = "loading";
  },
  [SPARES_INVENTORY_SUCCESS]: (state, resp) => {
    state.status = "success";
    Vue.set(state, "inventory", resp.payload);
  },

    [SPARES_INVENTORY_UPDATE_SUCCESS]: (state) => {
    state.status = "success";
  },
  [SPARES_INVENTORY_DELETE_ENTRY]: (state) => {
    state.status = "loading";
  },
  [SITE_EQUIPMENT_REQUEST]: (state) => {
    state.status = "loading";
  },
  [SITE_EQUIPMENT_SUCCESS]: (state, resp) => {
    state.status = "success";
    Vue.set(state, "equipment", resp.payload);
  },
  [SITE_EQUIPMENT_ERROR]: (state) => {
    state.status = "error";
  },
  [AUTHTOKEN_LOGOUT]: (state) => {
    state.equipment = [];
  },
  [EQUIPMENT_CREATE]: (state) => {
    state.status = "loading";
  },
  [EQUIPMENT_CREATE_SUCCESS]: (state) => {
    state.status = "success";
  },
  [EQUIPMENT_CREATE_ERROR]: (state) => {
    state.status = "error";
  },
  [EQUIPMENT_UPDATE]: (state) => {
    state.status = "loading";
  },
  [EQUIPMENT_UPDATE_SUCCESS]: (state) => {
    state.status = "success";
  },
  [EQUIPMENT_UPDATE_ERROR]: (state) => {
    state.status = "error";
  },
  [EQUIPMENT_DELETE]: (state) => {
    state.status = "loading";
  },
  [EQUIPMENT_DELETE_SUCCESS]: (state) => {
    state.status = "success";
  },
  [EQUIPMENT_DELETE_ERROR]: (state) => {
    state.status = "error";
  },
  [SPACK_STATS_REQUEST]: (state) => {
    state.status = "loading";
  },
  [SPACK_STATS_SUCCESS]: (state) => {
    state.status = "success";
  },
  [SPACK_STATS_ERROR]: (state) => {
    state.status = "error";
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
